import React from 'react'

export default function IndexPage() {
  return (
    <div className='container'>
      <div className='index-slider'>
        <img alt="" src="https://yatirimci.teknosa.com/Content/HomePageSlider/33141121yatirimciteknosabannertr.jpg" />
      </div>
      <div className='small-container'>
        <div className='row index-tables'>
          <div className='col-4'>
            <div className='title'>
              <span>Performans </span>
              <span><b>Grafiği</b></span>
            </div>
            <iframe src='https://webservice.foreks.com/foreks-web-widget/jUtql'></iframe>
          </div>
          <div className='col-4'>
            <div className='title'>
              <span>Performans </span>
              <span><b>Tablosu</b></span>
            </div>
            <iframe src='https://webservice.foreks.com/foreks-web-widget/Z57se'></iframe>
          </div>
          <div className='col-4'>
            <div className='title'>
              <span>Aylık </span>
              <span><b>Takvim</b></span>
            </div>
            <iframe src='https://webservice.foreks.com/foreks-web-widget/Z57se'></iframe>
          </div>
        </div>
        <div className='footer-links mt-5'>
          <div className='row'>
            <div className='col-9'>
              <div className='row'>
                <div className='col-4'>
                  <div className='footer-link'>
                    <b>YATIRIMCI<br />PAKETİ</b><br />
                    <small>Detay için tıklayınız...</small>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='footer-link'>
                    <b>SIKÇA SORULAN SORULAR</b><br />
                    <small>Detay için tıklayınız...</small>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='footer-link'>
                    <b>İLETİŞİM<br />BİLGİLERİ</b><br />
                    <small>Detay için tıklayınız...</small>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-3 text-center fs-5'>
              <div>Yatırımcı İlişkileri</div>
              <div>(216) 468 36 36</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
