import React from 'react'
import useFetch from '../hooks/useFetch'
import { useNavigate } from 'react-router-dom'

export default function ContentSideMenu() {
    const navigation = useNavigate()
    const { loading, error, data } = useFetch('articles?sort=id')
    if (loading) return null
    if (error) return <div>
        <p>Error :(</p>
        <p>{error}</p>
    </div>

    const linkto = (event, slug) => {
        event.preventDefault()
        navigation(`/page/${slug}`)
    }

    return (
        <div className='menu'>
            <ul>
                {data.data.map(e => (
                    <li key={`${e.documentId}`}>
                        <a href="javascript:;" onClick={(event) => linkto(event, e.slug)}>{e.title}</a>
                    </li>
                ))}
            </ul>
            <img alt="" src="https://yatirimci.teknosa.com/content/images/qr_surdurulebilirlik.jpg"></img>
        </div>
    )
}
