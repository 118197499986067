import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import 'bootstrap/dist/css/bootstrap.min.css';

import SiteHeader from './components/SiteHeader'
import IndexPage from './pages/IndexPage'
import ContentPage from './pages/ContentPage'
import ContentListPage from "./pages/ContentListPage";

function App() {
  return (
    <Router>
      <SiteHeader />
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/page/:slug" element={<ContentPage />} />
        <Route path="/search/:query" element={<ContentListPage />} />
      </Routes>
    </Router>
  )
}

export default App;
