import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Container, Nav, Navbar } from 'react-bootstrap';

import useFetch from '../hooks/useFetch'
import NavBarSearchForm from './NavBarSearchForm';

export default function SiteHeader() {
    const navigation = useNavigate()
    const { loading, error, data } = useFetch('articles?sort=id')
    if (loading) return null
    if (error) return <div>
        <p>Error :(</p>
        <p>{error}</p>
    </div>

    const linkto = (event, slug) => {
        event.preventDefault()
        navigation(`/page/${slug}`)
    }

    return (
        <div>
            <Navbar expand="lg" className="bg-dark">
                <Container fluid className='container p-2'>
                    <Link to="/">
                        <img className='header-logo' alt='' src="https://yatirimci.teknosa.com/App_Themes/Sablon/interface/_i/logo.png" />
                    </Link>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className=" me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                        </Nav>
                        <NavBarSearchForm />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar className="bg-light">
                <Container fluid className='container p-2'>
                    <Navbar.Collapse id="navbarScroll" className='d-flex justify-content-center'>
                        <Nav>
                            {data.data.map(e => (
                                <Nav.Link
                                    key={`${e.documentId}`}
                                    href="javascript:;"
                                    onClick={(event)=>linkto(event, e.slug)}>{e.title}</Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
