import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useFetch from '../hooks/useFetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDot } from '@fortawesome/free-solid-svg-icons/faCircleDot'

export default function ContentListPage() {
  const navigation = useNavigate()
  const { query } = useParams()
  const { loading, error, data } = useFetch(`articles?populate=*&filters[title][$containsi]=${query}`)
  if (loading) return null
  if (error) return <div>
    <p>Error :(</p>
    <p>{error}</p>
  </div>

const linkto = (event, slug) => {
  event.preventDefault()
  navigation(`/page/${slug}`)
}

  return (
    <div>
      <div className='content-breadcrumb-area'>
        <div className='content-breadcrumb'>
          Anasayfa - Arama - {query}
        </div>
      </div>
      <div className='small-container'>
        <h3>Arama Sonuçları</h3>
        <span>Aradığınız kelime: <b>'{query}'</b> <small>Toplam {data.data.length} içerik bulundu.</small></span>
        <div className='row'>
          <div className='content-body mt-2 col-12'>
            {data.data.length === 0 &&
              <p className='alert alert-warning m-0'>Aradığınız kriterlere göre sonuç bulunamadı.</p>
            }
            {data.data.length > 0 &&
              <div>
                <ul className='search-result-list'>
                  {data.data.map(e => (
                    <li key={`${e.documentId}`}>
                      <a className='d-flex' href="javascript:;"
                      onClick={(event)=>linkto(event, e.slug)}>
                        <FontAwesomeIcon className='me-2 mt-1' icon={faCircleDot} />
                        <span className='me-5'>{e.title}</span>
                        <div className='overme'>{e.description}</div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            }
          </div>
        </div>
      </div >
    </div >
  )
}
