import React from 'react'
import { useParams } from 'react-router-dom'
import useFetch from '../hooks/useFetch'
import ContentSideMenu from '../components/ContentSideMenu'

export default function ContentPage() {
  const { slug } = useParams()
  const { loading, error, data } = useFetch(`articles?populate=*&filters[slug][$eq]=${slug}`)
  if (loading) return null
  if (error) return <div>
    <p>Error :(</p>
    <p>{error}</p>
  </div>

  if (data.data.length === 0)
    return <div className='text-center'>
      <h1>404 : Not Found</h1>
    </div>

  const response = data.data[0]

  return (
    <div>
      <div className='content-breadcrumb-area'>
        <div className='content-breadcrumb'>
        Anasayfa - {response.title}
        </div>
      </div>
      <div className='small-container'>
        <h3>{response.title}</h3>
        <div className='row'>
          <div className='content-body mt-4 col-9'>
            <img alt={response.title} className="cover-image" src={response.cover?.url}></img>
            {response.blocks.find(z => z.__component === 'shared.rich-text')?.body}
          </div>
          <div className='content-sidemenu col-3'>
            <div className='title p-2 mt-4'>
              {response.title}
            </div>
            <ContentSideMenu />
          </div>
        </div>
      </div>
    </div>
  )
}
