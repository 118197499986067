import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function NavBarSearchForm() {
  const  navigation = useNavigate()
  const [queryText, setQueryText] = useState('')

  const search = (event) => {
    event.preventDefault()
    navigation(`/search/${queryText}`)
  }

  return (
    <Form className="d-flex" onSubmit={search}>
      <Form.Control type="search"
        name='query'
        placeholder="Ara..."
        className="me-2"
        aria-label="Search"
        value={queryText} onChange={(e) => setQueryText(e.target.value)}
      />
      <Button variant="outline-light" type='submit'>Ara</Button>
    </Form>
  )
}
